<template>
  <div class="rule-list">
    <page-content :dataList="ruleList" @selectionChange="selectionChange" class="page-content"
      :contentTableConfig="contentTableConfig" :queryInfo="queryInfo" @tableCellClick="handleTableCellClick">
      <template #cashIn="scope">
        {{ (scope.row.cashIn) / 100 }}
      </template>
      <template #cashProm="scope">
        {{ (scope.row.cashProm) / 100 }}
      </template>

      <template #handler="scope">
        <div @click.stop="" class="handle-btns">
          <el-button plain size="mini" @click.stop="handleEditClick(scope.row)" v-has="'recharge:rule:edit'">编辑</el-button>
          <el-button v-if="scope.row.saleStatus == 0" plain size="mini" @click.stop="handleBuyStateClick(scope.row, 1)">上架
          </el-button>
          <el-button v-if="scope.row.saleStatus == 1" plain size="mini" @click.stop="handleBuyStateClick(scope.row, 0)">下架
          </el-button>
          <el-button plain size="mini" @click.stop="handleremoveClick(scope.row)" v-has="'recharge:rule:delete'">删除</el-button>
        </div>
      </template>
    </page-content>
    <editRuleDialog :ruleDetail="currentEditRule" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible">
    </editRuleDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import editRuleDialog from './cpns/edit-rule-dialog/edit-rule.dialog.vue'
import { setRechargeSellState } from '@/service/main/target'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    editRuleDialog
  },
  setup() {
    const store = useStore()
    const ruleList = computed(() => store.state.target.ruleList)

    emitter.on('changeTargetRechargeRuleList', () => {
      initPage()
    })

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const handleEditClick = item => {
      currentEditRule.id = item.id
      currentEditRule.ruleName = item.ruleName
      currentEditRule.cashIn = item.cashIn
      currentEditRule.cashProm = item.cashProm
      dialogVisible.value = true
    }
    const currentEditRule = reactive({
      id: '',
      ruleName: '',
      cashIn: '',
      cashProm: ''
    })

    const handleremoveClick = item => {
      // console.log(item);
      store.dispatch('target/removeRuleAction', [COMPANY_ID, item.id])
    }

    const ruleState=ref('')

    const initPage = () => {
      store.dispatch('target/getRuleListAction', ruleState.value)
    }
    initPage()

    const handleBuyStateClick = async (item, type) => {
      let res = await setRechargeSellState(item.id, type)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        initPage()
      } else {
        ElMessage.error('操作失败')
      }
    }

    onUnmounted(() => {
      emitter.off('changeTargetRechargeRuleList')
    })

    return {
      ruleState,
      handleBuyStateClick,
      handleremoveClick,
      changeDialogVisible,
      dialogVisible,
      handleEditClick,
      contentTableConfig,
      ruleList,
      currentEditRule

    }

  }
})
</script>

<style scoped lang="less">
.rule-list {
  margin-top: 20px;
}
</style>




