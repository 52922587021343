<template>
  <div class="top-content">
    <el-button @click="handleAddRuleClick" type="primary" v-has="'recharge:rule:add'" >新建规则</el-button>
    <addRuleDialog v-if="dialogVisible"  @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"></addRuleDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import addRuleDialog from './cpns/add-rule-dialog/add-rule.dialog'

export default defineComponent({
  props: {

  },
  components: {
    addRuleDialog
  },
  setup() {
    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const handleAddRuleClick = () => {
      // console.log(`1`);
      dialogVisible.value = true
    }

    return {
      dialogVisible,
      handleAddRuleClick,
      changeDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.top-content {}
</style>




