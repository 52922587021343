export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '余额充值',
    path: ''
  }
]

export const contentTableConfig = {
  propList: [
    {
      prop: 'ruleName',
      label: '规则名称',
      minWidth: '60',
      slotName: 'ruleName'
    },
    {
      prop: 'cashIn',
      label: '储值金额(元)',
      minWidth: '200',
      slotName: 'cashIn'
    },

    {
      prop: 'cashProm',
      label: '赠送金额(元)',
      minWidth: '200',
      slotName: 'cashProm'
    },
    {
      prop: 'createTime',
      label: '创建时间',
      minWidth: '200',
      slotName: 'createTime'
    },
    { label: '操作', minWidth: '200', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

export const rules = {
  ruleName: [
    { required: true, message: '请输入规则名称', trigger: 'blur' }
  ],
  cashIn: [
    { required: true, message: '请输入储值金额', trigger: 'blur' }
  ],
  cashProm: [
    { required: true, message: '请输入储值金额', trigger: 'blur' }
  ]
}
