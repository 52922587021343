<template>
<div class="recharge-rule">
   <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="recharge-rule-content">
        <topcontent></topcontent>
        <ruleList></ruleList>
    </div>
</div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import ruleList from './cpns/rule-list/rule-list'
import topcontent from './cpns/top-content/top-content'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    ruleList,
    topcontent
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.recharge-rule{
  min-width: 980px;
.recharge-rule-content{
  padding: 20px;
  margin: 20px;
  background-color: #fff;
}
}
</style>




