<template>
  <div class="add-rule-dialog">
    <pageDialog :isShowFooter="true" width="500px" title="新增规则" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px"
        class="demo-ruleForm" status-icon>
        <el-form-item label="规则名称：" prop="ruleName">
          <el-input v-model="ruleForm.ruleName" />
        </el-form-item>
        <el-form-item label="储值金额：" prop="cashIn">
          <el-input v-model.number="ruleForm.cashIn" />
        </el-form-item>
        <el-form-item label="赠送金额：" prop="cashProm">
          <el-input v-model.number="ruleForm.cashProm" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="save">
          <el-button type="primary" size="small" @click="handleSaveClick(ruleFormRef)">保存</el-button>
        </div>
      </template>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { rules } from '../../../../config'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    pageDialog
  },
  setup(props, { emit }) {
    const store = useStore()

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }
    const ruleForm = reactive({
      ruleName: "",
      cashIn: '',
      cashProm: '',
      companyId: COMPANY_ID
    })

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          let rule = ruleList.value
          let index = rule.findIndex(ele => ele.ruleName == ruleForm.ruleName)
          if (index != -1) {
            ElMessage({
              message: '规则名称重复',
              type: 'warning',
            })
            return
          }
          changeDialogVisible()
          store.dispatch('target/addRuleAction', { ...ruleForm })
          emptyForm()
          // // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()
    const ruleList = computed(() => store.state.target.ruleList)


    return {
      ruleList,
      changeDialogVisible,
      ruleFormRef,
      ruleForm,
      handleSaveClick,
      rules,
    }

  }
})
</script>

<style scoped lang="less">
.add-rule-dialog {}
</style>




